@use '../../../common/styles/colours.scss' as c;

.airlines-container {
  display: flex;
  flex-direction: column;
}

.airlines-body-container {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10px;
  display: flex;
  height: 400px;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.page-heading {
  font-size: 34px;
  margin-bottom: 10px;
}

.airline-boxes {
  display: flex;
  flex-wrap: wrap;
}
