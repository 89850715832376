@use '../../../styles/colours.scss' as c;

.application-links-button {
  width: 220px;
  height: 64px;
  border-radius: 5px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/c.$gray-50+0,f1f4fa+50,f1f4fa+50,f1f4fa+51,ffffff+52,ffffff+52,ffffff+100 */
  background: c.$gray-50; /* Old browsers */
  background: -moz-linear-gradient(
    15deg,
    c.$gray-50 0%,
    c.$gray-50 50%,
    c.$gray-50 50%,
    c.$gray-50 51%,
    c.$white 52%,
    c.$white 52%,
    c.$white 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    15deg,
    c.$gray-50 0%,
    c.$gray-50 50%,
    c.$gray-50 50%,
    c.$gray-50 51%,
    c.$white 52%,
    c.$white 52%,
    c.$white 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    15deg,
    c.$gray-50 0%,
    c.$gray-50 50%,
    c.$gray-50 50%,
    c.$gray-50 51%,
    c.$white 52%,
    c.$white 52%,
    c.$white 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='c.$gray-50', endColorstr='c.$white',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  -webkit-transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
  -o-transition: background 0.5s linear;
  transition: background 0.5s linear;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-position: 0px 3px;
    -webkit-box-shadow: inset 6px 6px 50px -43px rgba(66, 68, 90, 1);
    -moz-box-shadow: inset 6px 6px 50px -43px rgba(66, 68, 90, 1);
    box-shadow: inset 6px 6px 50px -43px rgba(66, 68, 90, 1);
  }
  & img {
    margin-right: 10px;
  }
  & p {
    font-size: 20px;
  }
}

// To be moved to root

.klm-primary {
  color: c.$klm-primary;
}

.primary {
  color: c.$primary;
}

.air-black {
  color: c.$air-black;
}
