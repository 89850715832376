@use "../../styles/colours.scss" as c;

.info-box-container {
  display: flex;
  border-radius: 12px;
  flex: 1;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: c.$warning-box-blue;
  border: 1px solid c.$notice-border-blue;
}

.info-box-icon {
  color: c.$primary;
  font-size: 24px;
}

.info-box-content {
  flex: 1;
  margin-left: 20px;
  align-self: center;
}
