@use "../../styles/colours.scss" as c;

.checkbox-container {
  display: flex;
}

.checkbox-children {
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
}
