@use '../../styles/colours.scss' as c;

.button-container {
  display: inline-block;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 80px;
  text-align: center;
  color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: c.$base-primary;
  cursor: pointer;
}

.button-container-rounding {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}

.button-container:hover {
  background-color: c.$dark-alternate;
}

.button-text {
  background-color: c.$primary;
  cursor: pointer;
}

button {
  align-self: flex-end;
  font-size: 1rem !important;
  padding: 10px 15px !important;
  font-size: 12px;
  font-weight: 600;
  text-align: center;

  &.custom-cta {
    background: c.$primary;
    font-weight: bold;
    color: c.$white !important;
    &:hover {
      background: c.$primary-light;
    }
    &:active {
      box-shadow: inset 0 4px 2px 0 rgba(11, 37, 78, 0.5);
    }
    &:focus {
      outline: 3px solid #5585d2;
    }
  }
}

.secondary-button button {
  background: c.$blue-bg;
  color: c.$black !important;
  &:focus {
    outline: 3px solid c.$blue-bg;
  }
  &:hover {
    background: c.$gray-200;
  }
}

.alternate-button button {
  background: c.$option-green;
  &:focus {
    outline: 3px solid c.$option-green;
  }
  &:hover {
    background: c.$gray-200;
  }
}