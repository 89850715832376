@use '../../styles/colours.scss' as c;

.footer-container {
  display: flex;
  background-color: c.$primary;
  width: 100%;
  padding-top: 10px;
}

.footer-content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.upper-section {
  display: flex;
  width: 100%;
  padding: 0 60px;
  flex: 4 1;
  flex-wrap: wrap;
  justify-content: space-between;
  & .box {
    max-width: 350px;
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    text-align: left;
    color: c.$white;
    align-items: center;
    & i {
      font-size: 2.6rem;
      margin-right: 10px;
    }
    & h3 {
      font-size: 1.7rem;
      font-weight: bold;
      width: 70%;
      color: c.$white;
    }
    & .content {
      font-size: 1rem;
      & a {
        color: c.$link-primary;
      }
    }
  }
}

.middle-section {
  display: flex;
  background: c.$white;
  border-bottom: 1px solid c.$light-gray;
  width: 100%;
  justify-content: center;
  padding: 40px 0px;
}

.lower-section {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: c.$white;
  width: 100%;
  padding: 20px;
  & .footer-links {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    & .link {
      display: flex;
      margin: 0 10px;
    }
  }
  & .footer-logos {
    display: flex;
    & img {
      height: 40px;
      width: auto;
      margin: 0 10px;
    }
  }
}
