@use '../../../styles/colours.scss' as c;

.menu-bar-item {
  color: c.$air-black;  
  padding: 10px;
  justify-content: space-between;
  height: 40px;
  background-color: white;
  border-bottom: 1px solid c.$gray-200;
  display: flex;
  align-items: center;
}

.menu-bar-item-link {
  color: c.$black;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}