@use '../../common/styles/colours.scss' as c;
@use "../../common/styles/variables.scss" as v;

.single-news-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: c.$airtrade-blue;
}

.single-news {
  background: c.$white;
  width: 700px;
  margin: 3rem auto;
  padding: 4rem;
}

.single-news-content {
  margin-top: v.$header-height;
}

.meta-data {
  color: c.$gray-600;
  font-size: 0.7rem;
}

.single-news-meta-row {
  width: 200px;
  display: flex;
  justify-content: space-between;
  & .badge {
    margin-bottom: 3px;
  }
}
.single-news-heading {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.2rem;
}

.rich-content {
  margin-top: 10px;
  & p {
    margin: 5px 0;
  }
  & a {
    color: c.$link-primary;
  }
}
