@use "../../styles/colours.scss" as c;
@use "../../styles/variables.scss" as v;

.carousel-container {
  margin-top: v.$header-height;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: c.$placeholder-primary;
  & .icon {
    color: c.$white;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.carousel-upper-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 320px;
}

.carousel-navigation-button {
  display: flex;
  position: absolute;
  font-size: 26px;
  padding: 12px;
  cursor: pointer;
  &.left {
    left: 10px;
  }
  &.right {
    right: 10px;
  }
}

.carousel-navigation-button:hover {
  color: c.$base-primary;
}

.carousel-content-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}

.carousel-lower-container {
  display: flex;
  align-items: center;
  padding: 6px;
  position: absolute;
  margin-top: 295px;
}
