@use '../../styles/colours.scss' as c;

.application-links-container {
  display: flex;
  width: 100%;
  align-items: center;
  -webkit-box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  -moz-box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  background: c.$primary;
  min-height: 131px;
  justify-content: center;
}

.application-links-wrapper {
  margin: 0 20%;
  display: flex;
  justify-content: center;
  flex: 3 1;
  flex-wrap: wrap;
}

@media only screen and (min-width: 501px) {
  .application-links-wrapper {
    justify-content: space-between !important;
  }
}
