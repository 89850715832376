@use '../../styles/colours.scss' as c;

.tile-container {
  display: flex;
  text-align: center;
  flex: 0;
  background-color: c.$white;
  flex-direction: column;
  padding: 6px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 54px;
  min-width: 240px;
  width: 315px;
  min-height: 218px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.home-tile-heading {
  margin-top: 10px;
  font-weight: bold;
  padding: 0 15px;
}

.tile-upper-content {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.home-tile-content {
  padding: 0 15px;
  margin-bottom: 10px;
  & p {
    word-break: break-all;
  }
}
