@use '../../../common/styles/colours.scss' as c;

.news-list-item-container {
  display: flex;
  width: 264px;
  height: 317px;
  padding: 20px;
  margin: 1rem 2rem;
  &:hover {
    outline: 1px solid c.$gray-400;
    cursor: pointer;
  }
  & img {
    max-width: 224px;
    height: 150px;
  }
}

.meta-data {
  color: c.$gray-600;
  font-size: 0.7rem;
}
