@use '../../../common/styles/colours.scss' as c;

.news-container {
  display: flex;
  background-color: c.$white;
  flex: 1;
  flex-direction: column;
  border-radius: 5px;
  padding-top: 6px;
  margin-bottom: 30px;
}

.news-heading {
  font-weight: 600;
}

.news-widget-footer {
  & p {
    color: c.$news-green;
    padding: 10px 15px;
    font-size: 0.75rem;
  }
}
