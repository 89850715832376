@use '../../styles/colours.scss' as c;

/* Accordion overides */

.accordion__button {
  padding: 8px 14px !important;
  color: c.$air-black !important;
  background-color: c.$white !important;
}

.accordion__button[aria-expanded='true'] {
  border-top: none !important;
}

.accordion__panel {
  padding: 6px 12px 6px 24px !important;

  & a {
    color: c.$air-black !important;
  }
}

/* When colapsed the AccordionItemPane elements have display: none prop, but are still present in the DOM */
.accordion__panel:not([hidden]) {
  display: flex;
  flex-direction: column;
}

.link-item {
  padding: 6px 0;
}

.bottom-container {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
