@use '../../common/styles/colours.scss' as c;
@use "../../common/styles/variables.scss" as v;

.group-request-page-container {
  display: 'flex';
  text-align: left;
  background-color: c.$airtrade-blue;
  display: 'flex';
  padding-bottom: 20px;
  padding-top: v.$header-height;
}

.group-request-heading-container {
  margin-left: 10%;
  text-align: left;
}

.group-request-tab-heading {
  top: -1px;
}

.group-request-page-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.group-request-page-flight-options {
  display: flex;
  flex-wrap: wrap;
  flex:1;
}

.proceed-button-box {
  margin-top: 20px;
}
