@import "../../../common/styles/colours.scss";

.group-option-card-container {
    display: flex;
    justify-content: space-between;
    width: 330px;
    min-width: 330px;
    flex-direction: column;
    margin: 5px;
}

.group-option-card-heading {
    font-size: 20px;
    font-weight: 600;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border:2px solid $gray-border;
    border-bottom: 0;
    color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.group-option-box {
    background-color: $gray-50;
}

.group-option-box-selected {
    background-color: $selected-box-blue;
}

.group-option-card-content {
    padding: 10px;
    display: flex;
    border: 2px solid $gray-border;  
    flex-direction: column;
    flex-grow: 1;
}