@use "../../common/styles/variables.scss" as v;

.generic-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.generic-page-wrapper {
  width: 100%;
  padding: 0 80px;
  margin-bottom: 80px;
  margin-top: v.$header-height;
}

.generic-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
