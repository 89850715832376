@use '../../../common/styles/colours.scss' as c;

.widget-news-item-container {
  display: flex;
  border-bottom: 1px solid c.$dark-primary;
  padding: 15px;
  color: c.$air-black;
}

.widget-meta-data {
  color: c.$gray-600;
  font-size: 9px;
}

button.read-more {
  background: c.$news-green;
  font-size: 0.75rem;
  padding: 2px 8px;
  font-weight: bold;
}

img.widget-image {
  object-fit: cover;
  width: 80px;
  height: 80px;
  max-width: 80px;
}

.meta-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}

.content {
  line-height: 13px;
  font-size: 12px;
}

.heading {
  font-size: 13px;
}
