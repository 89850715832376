@use '../../../common/styles/colours.scss' as c;
@use "../../../common/styles/variables.scss" as v;

.news-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: v.$header-height;
}

.news-list-body-container {
  margin-top: 1.5rem;
  padding: 0 2rem;
  display: flex;
  height: 400px;
  width: 80%;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  height: 100%;
}

.ml-50-px {
  margin-left: 50px;
}

.pagination-container {
  margin-top: 30px;
  margin-bottom: 80px;
}

.pagination {
  display: flex;
  border: 1px solid c.$gray-400;
  border-radius: 5px;

  & button {
    width: 33px;
    height: 33px;
    border-right: 1px solid c.$gray-400;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  & button:last-child {
    border-right: none;
  }

  & button.active {
    background: c.$primary;
    color: c.$white;
  }

  & button.pageButton {
    font-size: 12px;
  }
}
