@use '../../../common/styles/colours.scss' as c;
@use '../../styles/breakpoints.scss' as b;

.search-box-container {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  & input {
    @include b.breakpoint(lg) {
      max-width: 400px;
    }

    border: 1px solid c.$gray-200;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.search-box-container.from-header {
  padding-right: 100px;
  @include b.breakpoint(lg) {
    padding-right: 0;
  }
}

.search-box-input-container {
  display: flex;

  width: 100%;

  input {
    width: 100%;
  }
}

.search-box-button {
  width: 40px;
  height: 40px;
  background-color: c.$news-green;
  color: c.$white;
  cursor: pointer;
  padding: 9px 7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
