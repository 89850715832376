@use "../../styles/colours.scss" as c;

.modal-header-bar {
  background-color: c.$base-primary;
  height: 40px;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
}

.modal-content-container {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  white-space: pre-line;
}

.modal-button-container {
  display: flex;
  min-height: 50px;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 12px;
}
