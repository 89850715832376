@use '../../styles/colours.scss' as c;

// Tabs

.react-tabs__tab {
  color: c.$primary;
  padding: 16px 35px;
  font-size: 13px;
  background: c.$gray-50;
  border: none;
  border-top: 4px solid c.$gray-50;
  & .icon {
    margin-right: 15px;
    color: c.$link-primary;
  }
  &:hover {
    background: c.$gray-200;
    border-color: c.$gray-200;
  }
}

.react-tabs__tab-panel {
  padding: 35px 40px;
}

.react-tabs__tab--selected {
  font-weight: bold;
  border-top: 4px solid c.$primary;
  border-radius: 0px;
  border-left: none;
  border-right: none;
  background: c.$white;
  & .icon {
    color: c.$primary;
  }

  &::after {
    content: none;
    border: none;
    width: 0px;
    height: 0px;
  }
}

.react-tabs__tab-list {
  border: none;
  margin: 0;
  text-align: left;
}

.react-tabs__tab-panel--selected {
  background: c.$white;
}

// Accordion

.accordion,
.accordion__item + .accordion__item {
  border: none !important;
}

.accordion__button {
  color: c.$primary !important;
  background-color: c.$gray-50 !important;
  position: relative;
  & .icon {
    color: c.$link-primary;
    margin-right: 15px;
  }
}

.accordion__panel {
  background: c.$white;
}

.accordion__button:before {
  content: none !important;
}

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
  background-color: c.$white !important;
  font-weight: bold;
  border-top: 4px solid c.$primary;
  & .icon {
    color: c.$primary;
  }
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(225deg);
}

.accordion__button:after {
  display: inline-block;
  position: absolute;
  right: 5px;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}
