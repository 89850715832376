
$breakpoints: (
  xs: 320,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1540,
  xxxl: 1700,
);


@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints , $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}px) {
      @content;
    }
  }
  @else {
    @warn 'No value found for `#{$breakpoint}`.';
  }
}