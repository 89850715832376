@use '../../../common/styles/colours.scss' as c;

.menu-bar-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  font-size: 14px;
  padding: 0 25px;
  white-space: nowrap;
  cursor: pointer;
}

.menu-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
