@use '../../styles/colours.scss' as c;

.breadcrumbs {
  font-size: 14px;
  display: flex;

  & a {
    color: c.$primary;
  }

  & .separator {
    color: c.$gray-400;
    margin: 0 5px;
  }

  & .breadcrumbs_active {
    color: c.$air-black;
    font-weight: bold;
  }
}
