@use '../../../common/styles/colours.scss' as c;

.lang-selector {
  cursor: pointer;
}

// Dropdown styles override

.language-selector-container {
  .dd-scroll-list {
    padding: 0 !important;
    overflow-y: hidden !important;
  }

  .dd-header span {
    margin-right: 0 !important;
  }

  .dd-header:hover {
    background-color: c.$gray-200;
  }

  .dd-list-item {
    font-weight: normal !important;
  }

  .dd-list-item:hover {
    background-color: c.$primary-light !important;
  }

  .dd-wrapper button {
    line-height: inherit !important;
    border: none !important;
  }

  .dd-wrapper {
    min-width: fit-content !important;
    width: 100% !important;
    min-width: fit-content !important;

    color: c.$link-primary !important;

    .dd-list {
      color: c.$black !important;

      border: none !important;

      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25) !important;
      width: auto;
    }

    .dd-header span {
      padding-left: 1rem;
    }

    .dd-header-title {
      margin: 0 !important;
    }
  }
}
