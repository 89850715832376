@use '../../common/styles/colours.scss' as c;

.home-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: c.$blue-bg;
}

.home-content-container {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.home-left-content-container {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  margin-left: 20px;
}

.home-right-content-container {
  display: flex;
  flex: 1;
  margin-right: 36px;
}

.home-card-heading {
  font-size: 18px;
  font-weight: 500;
  margin: 5px;
}

.home-card-text {
  font-size: 14px;
  margin: 2px;
  text-decoration: none;
}

@media only screen and (max-width: 950px) {
  .home-right-content-container {
    order: 1;
    margin: 0 20px 0 36px;
  }
  .home-left-content-container {
    order: 2;
  }
}
