@use '../../../common/styles/colours.scss' as c;

.airline-list-item-container {
  background: c.$white;
  width: 280px;
  padding: 2rem;
  margin: 2rem 2rem 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
