@use "../../common/styles/variables.scss" as v;
@use "../../common/styles/colours.scss" as c;

.results-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.results-wrapper {
  width: 100%;
  padding: 0 80px;
  margin-bottom: 80px;
  margin-top: v.$header-height;
}

.results-query {
  color: c.$gray-600;
  padding-bottom: 1rem;
}

a.results-link {
  width: 50vw;
  display: flex;
}

.results-content {
  background: c.$white;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0rem;
  width: 100%;
  & .results-date {
    color: c.$gray-600;
    margin: 0.25rem 0;
  }
  & .results-snippet {
    color: c.$air-black;
  }
  & h4 {
    color: c.$primary;
  }
  & p {
    text-align: left !important;
    & span {
      color: c.$link-primary !important;
    }
  }
}
