.base-checkbox {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
}

.checkbox-children {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-wrap: wrap;
  }