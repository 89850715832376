$air-black: #0b254e;
$airtrade-blue: #d9e1fe;
$base-primary: rgb(35, 93, 179);
$base-secondary: rgb(289, 59, 60);
$black-opacity: #00000050;
$black: #000;
$blue-bg: #e5eaf8;
$dark-alternate: rgb(161, 161, 161);
$dark-primary: #979797;
$error-red: #ff0033;
$gray-200: #ced3db;
$gray-400: #9da7b8;
$gray-50: #f1f4fa;
$gray-600: #6c7c94;
$gray-700: #536682;
$gray-border: #C2C8D2;
$klm-primary: #00a1de;
$light-gray: #ced3db;
$link-primary: #1fb6ff;
$news-green: #14c575;
$option-green: #2ac378;
$notice-border-blue: #57b3f9;
$placeholder-primary: rgb(216, 216, 216);
$primary-light: #5585d2;
$primary: #1c5cc2;
$shadow-primary: rgb(180, 180, 180);
$warning-box-blue: #e2f3fe;
$selected-box-blue: #e3f3fe;
$warning-icon-amber: #eab646;
$white: #fff;
