@use '../../common/styles/colours.scss' as c;
@use "../../common/styles/variables.scss" as v;

.single-airline-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.single-airline-wrapper {
  width: 100%;
  padding: 0 80px;
  margin-bottom: 80px;
}

.meta-data {
  color: c.$gray-600;
  font-size: 0.7rem;
}

.single-news-meta-row {
  width: 200px;
  display: flex;
  justify-content: space-between;
  & .badge {
    margin-bottom: 3px;
  }
}
.single-airline-heading {
  font-weight: 700;
  font-size: 3rem;
  padding: 30px 0px;
  margin-bottom: 10px;
  line-height: 2.2rem;
  text-align: left;
}

.rich-content {
  margin-top: 10px;
  & p {
    margin: 5px 0;
  }
  & a {
    color: c.$link-primary;
  }
}

.airline-banner {
  margin-top: v.$header-height;
  background: c.$gray-50;
  padding: 40px 0px;
  & .airline-banner-wrapper {
    padding: 0 80px;
    width: 100%;
  }
}

.centered-block {
  margin: 0 auto;
}
