.number-stepper-container {
    display: flex;
    border: 2px solid lightgray;
    border-radius: 6px;
    margin-top: 4px;
    margin-bottom: 4px;    
    width: 70%;
}

.number-stepper-control {
    height: 40px;
    flex:1;
    padding-left: 10px;
    border-radius: 6px;
}