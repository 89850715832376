@use '../../../styles/colours.scss' as c;

.menu-bar-item-parent {
    display: flex;
    color: c.$air-black;
    flex-direction: column;
    padding-right: 8px;
    justify-content: space-between;
    margin-right: 4px;
    flex:1;
    height: 40px;
    border-radius: 4px;
  }

.menu-bar-item-parent-container {
    display: flex;
    color: c.$air-black;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex:1;
    height: 40px;
    padding: 6px;
    cursor: pointer;
    border-radius: 4px;
  }

  .menu-bar-item-parent-container:hover {
      background-color: c.$gray-200;
  }

.menu-bar-item-text {
    color: c.$air-black;
    padding: 6px;
    padding-right: 8px;
    justify-content: space-between;
    margin-right: 4px;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
}

.menu-bar-item-parent-sub-container {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
  }