@use "../../styles/colours.scss" as c;

.select-box-container {
  display: 'flex';
  flex: 1;
  margin-bottom: 2px;
}

.select-box-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.select-box-heading {
  font-weight: 600;
}

.select-box-asterisk {
  color: c.$base-primary;
  font-size: 24px;
  line-height: 1.2;
}

.select-container {
  border: 1px solid c.$gray-400;
  border-radius: 6px;
  margin-top: 2px;
}

.select-control {
  border: 0;
  height: 36px;
  width: 100%;
}

.select-control:focus {
  outline: none;
}

.select-control-error-text {
  color: c.$error-red;
  margin-top: 4px;
  font-size: 10pt;
}
