@use '../../styles/colours.scss' as c;

.badge {
  background: c.$gray-200;
  font-size: 0.65rem;
  width: 90px;
  text-align: center;
  color: c.$gray-700;
  min-height: 15px;

  &.small {
    font-size: 0.45rem;
    padding: 0 0.15rem !important;
    height: 15px;
    line-height: 15px;
  }
}
