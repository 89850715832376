@use "./common/styles/colours.scss" as c;

@use './common/styles/breakpoints.scss' as b;

body {
  padding: 0;
  margin: 0;
  font-family: proxima-nova, sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

body.blue {
  background: c.$blue-bg;
}

a {
  color: c.$link-primary;
  text-decoration: none;
}

a:hover {
  color: c.$base-primary;
}

button {
  border-width: 0px;
}

input:disabled {
  background-color: c.$gray-200;
}

.page-heading {
  font-size: 20px;
  font-weight: 600;
}

.cta-button {
  color: c.$white;
  margin-right: 6px;
}

.page-heading-alignment {
  margin-top: 30px;
  margin-bottom: 30px;
}

.page-content-container {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  text-align: left;
  display: flex;
  width: 80%;
  flex-direction: column;
}

.page-subheading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
}

.base-colour-text {
  color: c.$base-primary;
}

.input-control {
  border: 0;
  min-height: 36px;
  width: 100%;
}

.input-control:focus {
  outline: none;
}

.input-control-error-text {
  color: c.$error-red;
  font-size: 10pt;
}

.alert-icon {
  font-size: 22px;
  padding: 4px;
}

.alert-text {
  font-size: 14px;
  color: red;
}

.splitbox-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.splitbox-container {
  display: flex;
  justify-content: flex-start;
}

.spacing-container {
  flex: 1;
}

.spaced-anchor {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.loader {
  border: 3px solid c.$dark-primary;
  border-top: 3px solid c.$base-primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.failed-validation-text {
  color: c.$error-red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Overrides
.react-tabs__tab {
  bottom: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: proxima-nova, sans-serif !important;
  font-weight: 700;
  font-style: normal;
  color: c.$air-black;
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}

.text-tiny {
  font-size: 10px;
}
.text-small {
  font-size: 12px;
}
.text-big {
  font-size: 18px;
}
.text-huge {
  font-size: 20px;
}

sub,
sup {
  font-size: 10px;
}

ul > li {
  list-style: disc;
}

ul.todo-list > li {
  list-style: none;
  & input[type='checkbox'] {
    margin-right: 10px;
  }
}

figure.image {
  margin-bottom: 1rem;
}

code {
  font-size: 14px;
}


.checkbox-control-container {
  border: 1px solid c.$gray-400;
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.checkbox-icon {
  font-size: 16px;
  font-weight: bold;
}

.checkbox-item {
  text-align: center;
}

// table {
//   font-size: 14px;
//   border: 1px solid c.$air-black !important;
//   border-collapse: separate;
//   border-radius: 5px;

//   // & tr:first-child td:first-child {
//   //   border-top-left-radius: 5px;
//   // }

//   // & tr:first-child td:last-child {
//   //   border-top-right-radius: 5px;
//   // }

//   // & tr:last-child td:first-child {
//   //   border-bottom-left-radius: 5px;
//   // }

//   // & tr:last-child td:last-child {
//   //   border-bottom-right-radius: 5px;
//   // }
//   & tr {
//     border: 1px solid c.$air-black !important;
//     & td {
//       border: 1px solid c.$air-black !important;
//       padding: 20px 12px;
//     }
//   }
// }

table {
  // border-collapse: separate;
  border: 1px solid c.$air-black;
  // border-spacing: 0;
  font-size: 14px;
  min-width: 350px;
  & tr {
    & td {
      padding: 12px;
    }
  }
}
table tr th,
table tr td {
  border-left: 1px solid c.$air-black;
  border-bottom: 1px solid c.$air-black;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid c.$air-black;
}
table tr th {
  background: #eee;
  border-top: 1px solid c.$air-black;
  text-align: left;
}

td:first-child,
th:first-child {
  border-left: none;
}

tr:first-child {
  border-top: 1px solid c.$air-black;
}

// /* top-left border-radius */
// table tr:first-child th:first-child {
//   border-top-left-radius: 6px;
// }

// /* top-right border-radius */
// table tr:first-child th:last-child {
//   border-top-right-radius: 6px;
// }

// /* bottom-left border-radius */
// table tr:last-child td:first-child {
//   border-bottom-left-radius: 6px;
// }

// /* bottom-right border-radius */
// table tr:last-child td:last-child {
//   border-bottom-right-radius: 6px;
// }

// /* Burger menu */

/* Position and sizing of burger button */

#react-burger-menu-btn,
#react-burger-cross-btn {
  font-size: 0 !important;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  left: initial;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: c.$primary;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: c.$link-primary;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 100% !important;
  @include b.breakpoint(md) {
    width: 300px !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1rem;
  box-shadow: 0px 5px 10px -6px rgba(171, 172, 181, 1) inset;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

// IMAGES

.image-style-align-center {
  margin: 0 auto;
  & img {
    margin: 0 auto;
  }
}

.image-style-align-right {
  margin-left: auto;
  & img {
    margin-left: auto;
  }
}

.image-style-align-left {
  margin-right: auto;
  & img {
    margin-right: auto;
  }
}
