@use '../../../styles/colours.scss' as c;

.carousel-item-container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.carousel-item-left-container {
  flex: 1;
}

.carousel-item-right-container {
  flex: 1;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: end;
}

.carousel-box {
  background: c.$black-opacity;
  display: flex;
  padding: 8px 4px;
  justify-content: space-between;
  flex-direction: column;
  min-height: 180px;
  margin-right: 10%;
  color: c.$white;
  border-radius: 8px;
  & .carousel-meta-container {
    display: flex;
    flex-flow: inherit;
    justify-content: center;
  }
  & .carousel-item-header {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bold;
    padding-bottom: 0.8rem;
  }
  & .carousel-item-text {
    font-size: 1rem;
    color: c.$white !important;
    & p {
      & span {
        color: c.$white !important;
      }
    }
  }
  & .carousel-box-meta-data {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    width: 200px;
    & .badge {
      font-size: 0.8rem;
    }
  }
  & button {
    align-self: flex-end;
    font-size: 1rem !important;
    padding: 10px 15px !important;
    &.custom-cta {
      background: c.$primary;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 420px) and (max-width: 768px) {
  .carousel-box-meta-data {
    display: none !important;
  }
}
