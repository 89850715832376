@use '../../common/styles/colours.scss' as c;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-content,
.login-page-boxes,
.login-page-buttons {
  display: flex;
}

.login-page-content {
  margin-top: 32px;
  flex-direction: column;
  width: 50%;
  align-items: center;
  text-align: center;
}

.login-page-sub-header {
  font-size: 24px;
}

.login-page-header-logo {
  width: 250px;
  margin-bottom: 56px;
}

.login-page-boxes {
  margin-top: 60px;
  margin-bottom: 100px;
  justify-content: space-between;

  .login-page-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  & img {
    height: 100px;
  }

  & p {
    font-size: 10px;
  }
}

.login-page-buttons {
  margin: 20px 0px 40px 0px;
  justify-content: center;
  & > * {
    margin: 0 5px;
  }
}
