@use '../../common/styles/colours.scss' as c;

.not-found-page {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  & img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }
}

.not-found-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 80%;
  border-radius: 5px;
  & h1,
  h2,
  h3,
  h4,
  h5 {
    color: c.$white !important;
  }
  & h1 {
    font-size: 10rem;
  }

  & h2 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }
  & h4 {
    margin-bottom: 50px;
    width: 70%;
    margin-left: 15%;
    text-align: center;
  }
  & button {
    margin-bottom: 50px;
    font-size: 1.5rem !important;
    padding: 15px 60px !important;
  }
}
