@use "../../styles/colours.scss" as c;

.input-box-container {
  display: 'flex';
  flex: 1;
  margin-bottom: 2px;
}

.input-box-heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.input-box-heading {
  font-weight: 600;
}

.input-box-asterisk {
  color: c.$base-primary;
  font-size: 24px;
  line-height: 1.2;
}

.input-container {
  border: 1px solid c.$gray-400;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.input-container-disabled {
  background-color: c.$gray-200;
  cursor: not-allowed;
}

.input-box-is-left-item {
  margin-right: 18px;
}

.input-box-error-spacing {
  margin-bottom: 19px;
}

.date-picker-container {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
}
