@use '../../styles/colours.scss' as c;
@use "../../styles/variables.scss" as v;

.header-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  -moz-box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  box-shadow: -3px 4px 30px -1px rgba(171, 172, 181, 1);
  background: c.$white;
  height: v.$header-height;
}

.header-wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.header-logo-container {
  padding: 10px;
  flex-shrink: 0;
}

.header-logo {
  height: 30px;
  object-fit: cover;
}

.news-link {
  padding: 10px 15px;
  font-size: 14px;
  align-items: center;
  & a {
    color: c.$news-green !important;
  }
}
