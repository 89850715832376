@use "../../../styles/colours.scss"  as c;

.carousel-selector {
  width: 28px;
  height: 4px;
  background-color: c.$white;
  opacity: 0.5;
  margin: 3px;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
}
