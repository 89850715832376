.group-request-booking-details {
    margin-top: 20px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}

.group-request-booking-details-container {
    display: flex;
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 14px;
}

.group-request-button-container {
    display: flex;
    align-items: center;
    align-content: center;
    color: red;
}

.group-request-booking-restart-button {
    margin-top: 12px;
    margin-bottom: 24px;
}

.group-request-booking-details-data {
    display: flex;
    text-align: left;
    justify-content: space-between
}

.group-request-booking-details-data-container {
    margin-left: 20px;
}

.group-request-page-lower-container {
    width: 40%;
    margin-top: 20px;
}

.group-request-page-lower-buttons {
    display: flex;
    margin-top: 16px;
}